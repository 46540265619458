<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>

            <div class="auth-page-right-content text-center">
              <ThemeButton class="d-block" />
              <h6>Password Updated</h6>

              <div class="success-img">
                <img src="../../../../assets/images/success.png" alt="" />
              </div>
              <p>Your Password has been updated!</p>
              <button
                type="button"
                class="auth-btn mt-2"
                @click="passwordUpdate(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Next</span>
              </button>
            </div>
            <div class="circle-one d-block d-md-none">
              <img src="../../../../assets/images/circle1.png" />
            </div>
            <div class="circle-two d-block d-md-none">
              <img src="../../../../assets/images/circle2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeButton from "../../../../components/Enterprise/Theme/ThemeButton.vue";
import { mapGetters, mapActions } from "vuex";
import AuthLeft from "../../../../components/Enterprise/authleft/AuthLeft.vue";

export default {
  name: "EnterprisePasswordUpdate",

  components: {
    ThemeButton,
    AuthLeft,
  },

  data: function() {
    return {
      isLoadingArray: [],
      para: "All Set. Let’s get you logged in.",
    };
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),

    passwordUpdate: function(index) {
      
      this.$set(this.isLoadingArray, index, true);
      //   this.sendLoginRequest(this.details).then((response) => {
      // this.errorMessage = response.data.message;
      setTimeout(() => {
        this.$set(this.isLoadingArray, index, false);
        this.$router.push({ name: "EnterpriseLogin" });
      }, 500);

      //   });
    },
  },
};
</script>
